import React, { useEffect } from "react";
import {
  ConnectWallet,
  useConnectionStatus,
  useAddress,
  useBalance,
} from "@thirdweb-dev/react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Home() {
  const connectionStatus = useConnectionStatus();
  const address = useAddress();
  const balance = useBalance(address);

  // Save address and balance to local storage when connected
  useEffect(() => {
    if (connectionStatus === "connected") {
      localStorage.setItem("walletAddress", address);
      localStorage.setItem("walletBalance", balance);
      localStorage.setItem("status", "connected");
    }
  }, [connectionStatus, address, balance]);

  // Remove address and balance from local storage when disconnected
  useEffect(() => {
    if (connectionStatus === "disconnected") {
      localStorage.removeItem("walletAddress");
      localStorage.removeItem("walletBalance");
      localStorage.removeItem("status");
    }
  }, [connectionStatus]);

  // Extract the specific balance value you want to render
  const formattedBalance = balance?.data?.formattedBalance || 0;

  return (
    <div className="container pt-3">
      <div className="bg-light">
        <div className="card">
          <div className="card-body text-center">
            <img src="./icon.png" style={{ width: 70 }} className="mb-3" />
            <div className="h3">
              <b>Ddex DeFi Smart Connect</b>{" "}
            </div>
            <div>
              You can connect you wallet with the smart wallet connect button.
              One for all, all for one.
            </div>
          </div>
        </div>
        <ConnectWallet
          dropdownPosition={{
            side: "bottom",
            align: "center",
          }}
          btnTitle={`Smart Wallet (${connectionStatus})`}
          style={{ width: "100%" }}
          className="mt-4 btn btn-outline-primary"
        />{" "}
        <div className="mt-3 text-center">
          <small className="">
            Connect your wallets to Ddex DeFi Wallet seamlessly.
          </small>
        </div>
      </div>
    </div>
  );
}
